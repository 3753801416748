import QueryString from 'qs';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { IDiscountCard } from '../../common/types';
import usePassengersManagement from '../../hooks/usePassengersManagement';
import request from '../../utils/request';
import {
  DEFAULT_LIMIT,
  DEFAULT_SEARCH_PARAMETERS,
  URL_AUTOCOMPLETE,
  URL_DISCOUNT_CARDS,
  URL_POPULAR_CITIES,
  URL_POPULAR_CITIES_FROM,
  URL_POPULAR_CITIES_TO,
} from './constants';
import {
  IPassenger,
  ISearchCity,
  ISearchContext,
  ISearchCustomer,
  ISearchParameters,
  searchParamsSchema,
} from './types';
import {
  formatAutoCompleteComCities,
  formatPopularCities,
  getSearchParametersFromUrl,
} from './utils';

export const SearchContext = createContext<ISearchContext>(null);

interface ResultProviderProps {
  children: React.ReactNode;
}

export const SearchProvider: React.FC<ResultProviderProps> = ({ children }) => {
  const [searchParameters, setSearchParameters] = useState<ISearchParameters>(
    DEFAULT_SEARCH_PARAMETERS,
  );

  const [customer, setCustomer] = useState<ISearchCustomer>({
    passengerId: null,
    mail: null,
    title: null,
    firstName: null,
    lastName: null,
    birthdate: null,
    phoneNumber: null,
    language: null,
  });

  const updatePassengers = (
    updatePassengersCallback: (currentPassengers: IPassenger[]) => IPassenger[],
  ) => {
    setSearchParameters((currentSearchParameters) => ({
      ...currentSearchParameters,
      passengers: updatePassengersCallback(
        currentSearchParameters.passengers,
      ).map((passenger) => {
        if (passenger.id === customer.passengerId) {
          setCustomer((customer) => ({
            ...customer,
            title: passenger.title,
            firstName: passenger.firstName,
            lastName: passenger.lastName,
            birthdate: passenger.birthdate,
          }));
        }

        return passenger;
      }),
    }));
  };

  const passengersManagement = usePassengersManagement({
    setPassengers: updatePassengers,
    passengers: searchParameters.passengers,
  });

  const setOrigin = (name: string, id: number) => {
    setSearchParameters((searchParameters) => ({
      ...searchParameters,
      origin: name,
      originId: id,
    }));
  };

  const setDestination = (name: string, id: number) => {
    setSearchParameters((searchParameters) => ({
      ...searchParameters,
      destination: name,
      destinationId: id,
    }));
  };

  const reverseCities = () => {
    setSearchParameters((searchParameters) => ({
      ...searchParameters,
      origin: searchParameters.destination,
      originId: searchParameters.destinationId,
      destination: searchParameters.origin,
      destinationId: searchParameters.originId,
    }));
  };

  const setOutboundDate = (date: string, from: number | null) => {
    setSearchParameters((searchParameters) => ({
      ...searchParameters,
      outboundDate: date,
      outboundTime: from,
    }));
  };

  const setReturnDate = (date: string, from: number | null) => {
    setSearchParameters((searchParameters) => ({
      ...searchParameters,
      returnDate: date,
      returnTime: from,
    }));
  };

  const setPassengers = useCallback((passengers: IPassenger[]) => {
    setSearchParameters((searchParameters) => ({
      ...searchParameters,
      passengers: passengers,
    }));
  }, []);

  /**
   * @description Returns a formatted search url
   * @param {ISearchParameters} searchParameters The parameters of the search to be performed
   * @returns {string}
   */
  const createSearchUrl = (
    searchParameters: ISearchParameters,
  ): string | null => {
    if (!searchParamsSchema.isValidSync(searchParameters)) {
      return;
    }

    const searchUrl = QueryString.stringify(
      {
        ...searchParameters,
        passengers: JSON.stringify(
          searchParameters.passengers.map((passenger) =>
            JSON.stringify({
              id: passenger.id,
              age: passenger.age,
              category: passenger.category,
              discountCards: passenger.discountCards,
            }),
          ),
        ),
      },
      { addQueryPrefix: true },
    );

    return searchUrl;
  };

  const getDiscountCards = async (): Promise<IDiscountCard[]> => {
    return await request(URL_DISCOUNT_CARDS);
  };

  const getPopularCities = async (
    limit: number = DEFAULT_LIMIT,
  ): Promise<ISearchCity[]> => {
    const cities = await request(`${URL_POPULAR_CITIES}/${limit}`);

    return formatPopularCities(cities);
  };

  const getPopularCitiesFrom = async (
    cityName: string,
    limit: number = DEFAULT_LIMIT,
  ): Promise<ISearchCity[]> => {
    const cities = await request(
      `${URL_POPULAR_CITIES_FROM}${cityName}/${limit}`,
    );

    return formatPopularCities(cities);
  };

  const getPopularCitiesTo = async (
    cityName: string,
    limit: number = DEFAULT_LIMIT,
  ): Promise<ISearchCity[]> => {
    const cities = await request(
      `${URL_POPULAR_CITIES_TO}${cityName}/${limit}`,
    );

    return formatPopularCities(cities);
  };

  const getSuggestedCities = async (
    cityName: string,
    partnerId?: string,
  ): Promise<ISearchCity[]> => {
    const parameters: URLSearchParams = new URLSearchParams({
      q: cityName,
    });

    if (partnerId) {
      parameters.append('partnerId', partnerId);
    }

    const cities = await request(`${URL_AUTOCOMPLETE}?${parameters}`);

    return formatAutoCompleteComCities(cities);
  };

  const value: ISearchContext = useMemo(
    () => ({
      searchParameters,
      setSearchParameters,
      getSearchParametersFromUrl,
      createSearchUrl,
      getPopularCitiesFrom,
      getPopularCitiesTo,
      getSuggestedCities,
      getPopularCities,
      setOrigin,
      setDestination,
      setOutboundDate,
      setReturnDate,
      reverseCities,
      getDiscountCards,
      setPassengers,
      customer,
      setCustomer,
      passengersManagement: passengersManagement,
    }),
    [customer, passengersManagement, searchParameters, setPassengers],
  );

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

export const useSearch = () => {
  const context = useContext(SearchContext);

  if (!context) {
  }

  return context;
};
