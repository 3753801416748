import PropTypes from 'prop-types';
import {
  appBannerMessages,
  appBannerScope,
} from '../components/AppPromotionBanner/messages';
import {
  submitButtonMessages,
  submitButtonScope,
} from '../components/ButtonSubmit/messages';
import {
  destinationCardMessages,
  destinationCardScope,
} from '../components/DestinationCard/messages';
import {
  detailedTripMessages,
  detailedTripScope,
} from '../components/DetailedTrip/messages';
import {
  dropzoneMessages,
  dropzoneScope,
} from '../components/Dropzone/messages';
import {
  errorMessagemessages,
  errorMessageScope,
} from '../components/ErrorMessage/messages';
import { expandMessages, expandScope } from '../components/Expand/messages';
import { footerMessages, footerScope } from '../components/Footer/messages';
import { funnelMessages, funnelscope } from '../components/Funnel/messages';
import {
  MaintenanceMessages,
  maintenanceScope,
} from '../components/Maintenance/messages';
import { navbarMessages, navbarScope } from '../components/Navbar/messages';
import {
  newModalMessage,
  newModalScope,
} from '../components/NewModal/messages';
import {
  nextDayMessages,
  nextDayScope,
} from '../components/NextDay/messages.js';
import {
  passengersMessages,
  passengersScope,
} from '../components/Passengers/messages';
import {
  priceCalendarMessages,
  priceCalendarScope,
} from '../components/PriceCalendar/messages';
import { ratingMessages, ratingScope } from '../components/Rating/messages';
import {
  travelSearchBarMessages,
  travelSearchBarScope,
} from '../components/TravelSearchBar/messages';
import { tripMessages, tripScope } from '../components/Trip/messages';
import {
  tripComparatorMessages,
  tripComparatorScope,
} from '../components/TripComparator/messages';
import {
  tripRecapMessages,
  tripRecapScope,
} from '../components/TripRecap/messages';
import {
  tripSimpleMessages,
  tripSimpleScope,
} from '../components/TripSimple/messages.js';
import {
  trustBoxMessages,
  trustBoxScope,
} from '../components/TrustBox/messages';
import { aboutMessages, aboutScope } from '../containers/About/messages';
import { cityMessages, cityScope } from '../containers/City/View/messages';
import {
  companyMessages,
  companyScope,
} from '../containers/Company/View/messages';
import {
  confirmationMessages,
  confirmationScope,
} from '../containers/Confirmation/messages';
import { faqMessages, faqScope } from '../containers/Faq/View/messages';
import { homepageScope } from '../containers/HomePage/View/constants';
import { homepageMessages } from '../containers/HomePage/View/messages';
import {
  howItWorksMessages,
  howItWorksScope,
} from '../containers/howItWorks/messages';
import {
  largeTravelSliderMessages,
  largeTravelSliderScope,
} from '../containers/LargeTravelSlider/View/messages';
import {
  legalMentionMessages,
  legalMentionScope,
} from '../containers/LegalMention/messages';
import { loginMessages, loginScope } from '../containers/Login/messages';
import {
  mountainMessages,
  mountainScope,
} from '../containers/Mountain/View/messages';
import {
  newsletterMessages,
  newsletterScope,
} from '../containers/Newsletter/messages';
import {
  notFoundPageMessages,
  notFoundPageScope,
} from '../containers/NotFoundPage/messages';
import { ordersScope } from '../containers/Orders/constants';
import { ordersMessages } from '../containers/Orders/View/messages.js';
import {
  partnersMessages,
  partnersScope,
} from '../containers/Partners/View/messages';
import {
  passengersInfoMessages,
  passengersInfoScope,
} from '../containers/PassengersInfo/messages';
import { privacyMessages, privacyScope } from '../containers/Privacy/messages';
import {
  redirectMessages,
  redirectScope,
} from '../containers/Redirect/View/messages';
import {
  resultsMessages,
  resultsScope,
} from '../containers/Results/View/messages';
import {
  searchMessages,
  searchScope,
} from '../containers/Search/View/messages';
import {
  searchHeaderMessages,
  searchHeaderScope,
} from '../containers/SearchHeader/messages';
import {
  stationMessages,
  stationMessagesScope,
} from '../containers/Station/View/messages';
import {
  stationSitemapMessages,
  stationSitemapScope,
} from '../containers/StationSitemap/View/messages';
import { supportMessages, supportScope } from '../containers/Support/messages';
import {
  termsOfSalesMessages,
  termsOfSalesScope,
} from '../containers/TermsOfSales/messages';
import {
  tsbMessages,
  tsbScope,
} from '../containers/TravelSearchBar/View/messages';

const getTranslation = (messages, scope, locale) => {
  const translation = {};
  /* eslint-disable no-return-assign */
  Object.entries(messages).map(
    (entry) => (translation[`${scope}.${entry[0]}`] = entry[1][locale]),
  );
  /* eslint-enable */
  return translation;
};

export const formattedMessagePropTypes = PropTypes.shape({
  de: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired,
  en: PropTypes.string.isRequired,
  es: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  it: PropTypes.string.isRequired,
  nl: PropTypes.string.isRequired,
});

const getFullTranslations = (locale) => ({
  ...getTranslation(passengersMessages, passengersScope, locale),
  ...getTranslation(expandMessages, expandScope, locale),
  ...getTranslation(destinationCardMessages, destinationCardScope, locale),
  ...getTranslation(homepageMessages, homepageScope, locale),
  ...getTranslation(largeTravelSliderMessages, largeTravelSliderScope, locale),
  ...getTranslation(redirectMessages, redirectScope, locale),
  ...getTranslation(resultsMessages, resultsScope, locale),
  ...getTranslation(tsbMessages, tsbScope, locale),
  ...getTranslation(notFoundPageMessages, notFoundPageScope, locale),
  ...getTranslation(legalMentionMessages, legalMentionScope, locale),
  ...getTranslation(termsOfSalesMessages, termsOfSalesScope, locale),
  ...getTranslation(partnersMessages, partnersScope, locale),
  ...getTranslation(ratingMessages, ratingScope, locale),
  ...getTranslation(detailedTripMessages, detailedTripScope, locale),
  ...getTranslation(appBannerMessages, appBannerScope, locale),
  ...getTranslation(newsletterMessages, newsletterScope, locale),
  ...getTranslation(aboutMessages, aboutScope, locale),
  ...getTranslation(howItWorksMessages, howItWorksScope, locale),
  ...getTranslation(faqMessages, faqScope, locale),
  ...getTranslation(privacyMessages, privacyScope, locale),
  ...getTranslation(cityMessages, cityScope, locale),
  ...getTranslation(companyMessages, companyScope, locale),
  ...getTranslation(searchMessages, searchScope, locale),
  ...getTranslation(mountainMessages, mountainScope, locale),
  ...getTranslation(stationMessages, stationMessagesScope, locale),
  ...getTranslation(stationSitemapMessages, stationSitemapScope, locale),
  ...getTranslation(ordersMessages, ordersScope, locale),
  ...getTranslation(tripSimpleMessages, tripSimpleScope, locale),
  ...getTranslation(nextDayMessages, nextDayScope, locale),
  ...getTranslation(trustBoxMessages, trustBoxScope, locale),
  ...getTranslation(submitButtonMessages, submitButtonScope, locale),
  ...getTranslation(newModalMessage, newModalScope, locale),
  ...getTranslation(supportMessages, supportScope, locale),
  ...getTranslation(MaintenanceMessages, maintenanceScope, locale),
  ...getTranslation(travelSearchBarMessages, travelSearchBarScope, locale),
  ...getTranslation(funnelMessages, funnelscope, locale),
  ...getTranslation(tripMessages, tripScope, locale),
  ...getTranslation(tripRecapMessages, tripRecapScope, locale),
  ...getTranslation(confirmationMessages, confirmationScope, locale),
  ...getTranslation(passengersInfoMessages, passengersInfoScope, locale),
  ...getTranslation(stationMessages, stationMessagesScope, locale),
  ...getTranslation(footerMessages, footerScope, locale),
  ...getTranslation(priceCalendarMessages, priceCalendarScope, locale),
  ...getTranslation(errorMessagemessages, errorMessageScope, locale),
  ...getTranslation(tripComparatorMessages, tripComparatorScope, locale),
  ...getTranslation(dropzoneMessages, dropzoneScope, locale),
  ...getTranslation(searchHeaderMessages, searchHeaderScope, locale),
  ...getTranslation(navbarMessages, navbarScope, locale),
  ...getTranslation(loginMessages, loginScope, locale),
});
export { getFullTranslations };
