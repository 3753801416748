import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import tw from 'tailwind.macro';
import { IAppContext } from '../../contexts/App/types';
import { ChevronIcon } from '../../images/icons/chevron';
import { MenuIcon } from '../../images/icons/menu';
import { colors, screens } from '../../styles/constants';
import { Icon24 } from '../Icon';
import { MenuLink } from './Common';
import { Language, LANGUAGES_ITEMS, SecondaryDrawerSections } from './config';
import { navbarMessages } from './messages';

const DesktopMenuContainer = styled.div<{ isPartner: boolean }>`
  ${tw`hidden h-full flex justify-end items-center  lg:flex`}

  gap: var(--size-huge);

  grid-column-start: 4;
  grid-column-end: 5;

  @media (min-width: ${screens.lg}) {
    ${({ isPartner }) =>
      isPartner
        ? `
        grid-column-start: 11;
        grid-column-end: 13;
        `
        : `grid-column-start: 3;
    grid-column-end: 13;`}
  }
`;

const MenuButton = styled.button`
  ${tw`p-0 h-full flex justify-end items-center `}
`;

const DesktopMenuNavLinks = styled.nav`
  ${tw`hidden lg:flex lg:items-center`}
  gap: var(--size-big)
`;

const LanguageButton = styled.button<{ isPartner: boolean }>`
  ${({ isPartner }) =>
    isPartner
      ? tw`flex items-center`
      : css`
          border-left: 1px solid ${colors['grey-300']};
          ${tw`hidden lg:flex lg:items-center`}
        `}

  ${tw`py-small pl-medium`}
  gap: 12px;
`;

type DesktopNavigationProps = {
  onOpenSecondaryDrawer: (section: SecondaryDrawerSections) => void;
  onOpenPrimaryDrawer: () => void;
  language: Language;
  isMobileApp: boolean;
  partner: IAppContext['partner'];
};

export const DesktopNavigation = (props: DesktopNavigationProps) => {
  const currentLanguageItem = LANGUAGES_ITEMS.find(
    (item) => item.id === props.language,
  );

  return (
    <DesktopMenuContainer
      data-testid="NavbarNavigation"
      isPartner={!!props.partner}
    >
      {props.isMobileApp === false && !props.partner && (
        <DesktopMenuNavLinks data-testid="NavbarDesktopNavLinks">
          <MenuLink
            as="button"
            data-testid="NavbarOpenCarriersButton"
            onClick={() => props.onOpenSecondaryDrawer('carriers')}
          >
            <FormattedMessage {...navbarMessages.carriersSection} />
          </MenuLink>

          <MenuLink
            as="button"
            data-testid="NavbarOpenTopTripsButton"
            onClick={() => props.onOpenSecondaryDrawer('topTrips')}
          >
            <FormattedMessage {...navbarMessages.topTripsSection} />
          </MenuLink>
        </DesktopMenuNavLinks>
      )}

      {!props.partner && (
        <MenuButton
          data-testid="NavbarHanburgerMenuButton"
          onClick={props.onOpenPrimaryDrawer}
        >
          <MenuIcon
            color={colors['secondary-400']}
            customStyle={css`
              ${Icon24}
            `}
          />
        </MenuButton>
      )}

      <LanguageButton
        data-testid="NavbarSelectLanguageButton"
        isPartner={!!props.partner}
        onClick={() => props.onOpenSecondaryDrawer('languages')}
      >
        <img
          alt="change language"
          src={currentLanguageItem.icon as string}
          style={currentLanguageItem.iconStyle}
        />

        <ChevronIcon
          color={colors['secondary-400']}
          customStyle={css`
            transform: rotate(270deg);
          `}
        />
      </LanguageButton>
    </DesktopMenuContainer>
  );
};
