import 'whatwg-fetch';
import logoAlbu from '../images/providers/logo_albu.png';
import logoAlsa from '../images/providers/logo_alsa.svg';
import logoAltibus from '../images/providers/logo_altibus.png';
import logoAmp from '../images/providers/logo_amp.svg';
import logoAndbus from '../images/providers/logo_andbus.png';
import logoAutozug from '../images/providers/logo_autozug.svg';
import logoAve from '../images/providers/logo_ave.svg';
import logoBlablacar from '../images/providers/logo_blablacar.svg';
import logoBlablacarBus from '../images/providers/logo_blablacar_bus.svg';
import logoCarsRegion from '../images/providers/logo_cars_region.png';
import logoDB from '../images/providers/logo_db.svg';
import logoEsle from '../images/providers/logo_esle.png';
import logoEurocity from '../images/providers/logo_eurocity.svg';
import logoEurostar from '../images/providers/logo_eurostar.svg';
import logoThalys from '../images/providers/logo_eurostar.svg';
import logoFlibco from '../images/providers/logo_flibco.svg';
import logoFlixbus from '../images/providers/logo_flixbus.svg';
import logoFlixtrain from '../images/providers/logo_flixtrain.svg';
import logoFrecciabianca from '../images/providers/logo_frecciabianca.svg';
import logoFrecciaargento from '../images/providers/logo_frecciargento.svg';
import logoFrecciarossa from '../images/providers/logo_frecciarossa.svg';
import logoIce from '../images/providers/logo_ice.svg';
import logoIntercites from '../images/providers/logo_intercites.svg';
import logoInterSaj from '../images/providers/logo_intersaj.png';
import logoIryo from '../images/providers/logo_iryo.svg';
import logoItabus from '../images/providers/logo_itabus.svg';
import logoItal from '../images/providers/logo_ital.svg';
import logoItalo from '../images/providers/logo_italo.svg';
import logoKeolis from '../images/providers/logo_keolis.svg';
import logoLdbi from '../images/providers/logo_lbdi.svg';
import logoLeoExpress from '../images/providers/logo_leo_express.svg';
import logoLyria from '../images/providers/logo_lyria.svg';
import logoMagicalShuttle from '../images/providers/logo_magical_shuttle.jpg';
import logoMarinobus from '../images/providers/logo_marino_bus.svg';
import logoMegabus from '../images/providers/logo_megabus.svg';
import logoNationalExpress from '../images/providers/logo_national_express.png';
import logoNbea from '../images/providers/logo_nbea.png';
import logoNdvy from '../images/providers/logo_ndvy.png';
import logoNightjet from '../images/providers/logo_nightjet.svg';
import logoNtv from '../images/providers/logo_ntv.svg';
import logoObb from '../images/providers/logo_obb.png';
import logoOuigoGrandeVitesse from '../images/providers/logo_ouigo_grande_vitesse.svg';
import logoOuigoVitesseClassique from '../images/providers/logo_ouigo_vitesse_classique.svg';
import logoPexr from '../images/providers/logo_pexr.png';
import logoRailjet from '../images/providers/logo_railjet.svg';
import logoRegiojet from '../images/providers/logo_regiojet.svg';
import logoRenfe from '../images/providers/logo_renfe.svg';
import logoRer from '../images/providers/logo_rer.svg';
import logoRhex from '../images/providers/logo_rhex.png';
import logoRiviera from '../images/providers/logo_riviera.png';
import logoRussianRailways from '../images/providers/logo_russian_railways.svg';
import logoSBB from '../images/providers/logo_sbb.svg';
import logoSj from '../images/providers/logo_sj.svg';
import logoSncf from '../images/providers/logo_sncf.svg';
import logoSupercity from '../images/providers/logo_supercity.png';
import logoSwisstours from '../images/providers/logo_swisstours.png';
import logoTer from '../images/providers/logo_ter_sncf.svg';
import logoTgv from '../images/providers/logo_tgv.svg';
import logoTgvInoui from '../images/providers/logo_tgv_inoui.svg';
import logoTgvNomad from '../images/providers/logo_tgv_nomad.svg';
import logoToto from '../images/providers/logo_toto.png';
import logoTrenitalia from '../images/providers/logo_trenitalia.svg';
import logoWebstbahn from '../images/providers/logo_westbahn.svg';

export const API_URL = process.env.REACT_APP_API_URL;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV;
export const PAYLINE_JS_URL = process.env.REACT_APP_PAYLINE_JS_URL;
export const PAYLINE_CSS_URL = process.env.REACT_APP_PAYLINE_CSS_URL;
export const PUBLIC_URL = process.env.PUBLIC_URL;

export const URL_LEARN_SEARCH = `${API_URL}/learn/user-searchs`;
export const URL_DATA = `${API_URL}/data`;
export const URL_LINKS = `${API_URL}/cities/popular`;
export const URL_CART = `${API_URL}/booking/carts`;
export const URL_ADMIN_AUTHENTICATION = `${API_URL}/auth/google`;
export const URL_ADMIN_WIDGET_BOOKING = `${API_URL}/widgetBookings`;
export const URL_ADMIN_EDITABLE_CONTENT = `${API_URL}/editableContent`;
export const URL_ORDER_TICKETS = `${API_URL}/orderTickets`;

export const ASSETS_PATH = `${window.location.origin}/assets`;
export const CITY_ASSETS_PATH = `${ASSETS_PATH}/city`;
export const COMPANY_ASSETS_PATH = `${ASSETS_PATH}/company`;

export const LINK_DIRECTION_FROM = 'from';
export const LINK_DIRECTION_TO = 'to';

export const BASIC_REQUEST_HEADERS = {
  'Content-Type': 'application/json',
  'user-lang': 'fr_FR',
};

export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';
export const ADMIN_TOKEN = 'adminToken';

export const TOKEN_EXPIRED_ERROR_KEY = 'authentication:client:tokenExpired';
export const UNAUTHORIZED_ROUTE_ERROR_KEY =
  'authentication:client:unauthorizedRoute';

export const EDITABLE_CONTENT_DUPLICATE_ERROR_KEY =
  'editableContents:client:duplicate';

export const BRANDS = {
  Ouigo: 'Ouigo',
  'OUIGO Grande vitesse': 'OUIGO Grande vitesse',
  'OUIGO Vitesse classique': 'OUIGO Vitesse classique',
  TGV: 'TGV',
  TGVInoui: 'TGV Inoui',
  TGVAtlantique: 'TGV Atlantique',
  TGVDuplex: 'TGV Duplex',
  TER: 'TER',
  CarTer: 'Car TER',
  Intercite: 'Intercités',
  InterciteNuit: 'Intercités de nuit',
  Alsa: 'Alsa',
  Altibus: 'Altibus',
  CarsRegion: 'Cars Région',
  Bus: 'Bus',
  Flixbus: 'Flixbus',
  Lyria: 'Lyria',
  Eurostar: 'Eurostar',
  Renfe: 'Renfe',
  IRYO: 'Iryo',
  DB: 'Deutshe Bahn',
  SBB: 'SBB',
  CAMP: 'Campania Express',
  ITAL: 'Italo Treno',
  ALBU: 'Alpybus',
  TOTO: 'Totoom',
  ESLE: 'European Sleeper',
};

export const PROVIDERS_LOGOS = {
  ave: { src: logoAve, alt: 'logo Ave' },
  alsa: { src: logoAlsa, alt: 'logo ALSA' },
  altibus: { src: logoAltibus, alt: 'logo Altibus' },
  'cars région': { src: logoCarsRegion, alt: 'logo Cars Région' },
  'autocar sncf': { src: logoSncf, alt: 'logo SNCF' },
  blablacar: { src: logoBlablacar, alt: 'logo Blablacar' },
  'bus blablacar': { src: logoBlablacarBus, alt: 'logo Blablacar Bus' },
  bus: { src: logoSncf, alt: 'logo SNCF' },
  'car ter': { src: logoTer, alt: 'logo TER' },
  cisalpino: { src: logoEurocity, alt: 'logo Eurocity' },
  db: { src: logoDB, alt: 'logo db' },
  'db autozug': { src: logoAutozug, alt: 'logo Autozug' },
  'es av fast': { src: logoTrenitalia, alt: 'logo Trenitalia' },
  'es fast': { src: logoTrenitalia, alt: 'logo Trenitalia' },
  'en italie': { src: logoTrenitalia, alt: 'logo Trenitalias' },
  eurocity: { src: logoEurocity, alt: 'logo Eurocity' },
  'eurocity train': { src: logoEurocity, alt: 'logo Eurocity' },
  andbus: { src: logoAndbus, alt: 'logo Andbus' },
  'inter saj': { src: logoInterSaj, alt: 'logo Inter Saj' },
  flibco: { src: logoFlibco, alt: 'logo Flibco' },
  eurostar: {
    src: logoEurostar,
    alt: 'logo Eurostar',
    style: {
      height: '4rem',
    },
  },
  'express ic': { src: logoIce, alt: 'logo ICE' },
  flixbus: { src: logoFlixbus, alt: 'logo Flixbus' },
  frecciabianca: { src: logoFrecciabianca, alt: 'logo Frecciabianca' },
  frecciargento: { src: logoFrecciaargento, alt: 'logo Frecciargento' },
  frecciarossa: { src: logoFrecciarossa, alt: 'logo Frecciarossa' },
  ice: { src: logoIce, alt: 'logo ICE' },
  'intercity notte italia': {
    src: logoNtv,
    alt: 'logo Nuovo Trasporto Viaggiatori',
  },
  intercity: { src: logoTrenitalia, alt: 'logo Trenitalia' },
  'intercité 100% eco': { src: logoIntercites, alt: 'logo Intercités' },
  'intercités duplex': { src: logoIntercites, alt: 'logo Intercités' },
  'intercités de nuit': { src: logoIntercites, alt: 'logo Intercités' },
  intercités: { src: logoIntercites, alt: 'logo Intercités' },
  iryo: { src: logoIryo, alt: 'logo iryo' },
  'italo treno': { src: logoItal, alt: 'logo ital' },
  alpybus: { src: logoAlbu, alt: 'logo albu' },
  'european sleeper': { src: logoEsle, alt: 'logo esle' },
  totoom: { src: logoToto, alt: 'logo toto' },
  'campania express': {
    src: logoPexr,
    alt: 'logo pexr',
  },
  italo: { src: logoItalo, alt: 'logo Italo' },
  'le bus direct': { src: logoLdbi, alt: 'logo Le Bus Direct' },
  lyria: { src: logoLyria, alt: 'logo Lyria' },
  'magical shuttle': { src: logoMagicalShuttle, alt: 'logo Magical Shuttle' },
  megabus: { src: logoMegabus, alt: 'logo Megabus' },
  none: { src: logoSncf, alt: 'logo SNCF' },
  ntv: { src: logoNtv, alt: 'logo Nuovo Trasporto Viaggiatori' },
  'national express': {
    src: logoNationalExpress,
    alt: 'logo National Express',
  },
  'navette keolis': { src: logoKeolis, alt: 'logo Keolis' },
  'navette paris beauvais': {
    src: logoNbea,
    alt: 'logo Navette Paris Beauvais',
    style: {
      height: '4rem',
    },
  },
  'navette de vatry': { src: logoNdvy, alt: 'logo Navette de Vatry' },
  navette: { src: logoSncf, alt: 'logo SNCF' },
  'ouigo españa': { src: logoOuigoGrandeVitesse, alt: 'logo Ouigo' },
  ouigo: {
    src: logoOuigoGrandeVitesse,
    alt: 'logo Ouigo',
    style: { height: '4rem' },
  },
  'ouigo grande vitesse': {
    src: logoOuigoGrandeVitesse,
    alt: 'logo OUIGO Grande vitesse',
    style: { height: '4rem' },
  },
  'ouigo vitesse classique': {
    src: logoOuigoVitesseClassique,
    alt: 'logo OUIGO Vitesse classique',
    style: { height: '4rem' },
  },
  flixtrain: {
    src: logoFlixtrain,
    alt: 'logo FlixTrain',
    style: { height: '1.8rem' },
  },
  regionale: {
    src: logoTrenitalia,
    alt: 'logo Trenitalia',
  },
  'regionale veloce': {
    src: logoTrenitalia,
    alt: 'logo Trenitalia',
  },
  rhônexpress: {
    src: logoRhex,
    alt: 'logo RhôneExpress',
    style: {
      height: '4rem',
    },
  },
  itabus: {
    src: logoItabus,
    alt: 'logo Itabus',
    style: { height: '4rem' },
  },
  marinobus: {
    src: logoMarinobus,
    alt: 'logo Marino bus',
    style: { height: '4rem' },
  },
  'navette aéroport marseille provence': { src: logoAmp, alt: 'logo AMP' },
  pendolino: { src: logoSupercity, alt: 'logo Supercity' },
  'rer a': { src: logoRer, alt: 'logo RER' },
  'rer b': { src: logoRer, alt: 'logo RER' },
  'rer c': { src: logoRer, alt: 'logo RER' },
  'rer d': { src: logoRer, alt: 'logo RER' },
  'rer e': { src: logoRer, alt: 'logo RER' },
  rer: { src: logoRer, alt: 'logo RER' },
  railjet: { src: logoRailjet, alt: 'logo Railjet' },
  regiojet: { src: logoRegiojet, alt: 'logo Regiojet' },
  'riviera night': { src: logoRiviera, alt: 'logo Riviera Night' },
  riviera: { src: logoRiviera, alt: 'logo Riviera Night' },
  sbb: { src: logoSBB, alt: 'logo sbb' },
  'sj train': { src: logoSj, alt: 'logo SJ' },
  'spanish train duplex': { src: logoSncf, alt: 'logo SNCF' },
  supercity: { src: logoSupercity, alt: 'logo Supercity' },
  'swiss tours': { src: logoSwisstours, alt: 'logo Swiss Tours' },
  ter: { src: logoTer, alt: 'logo TER' },
  'tgv atlantique': { src: logoTgv, alt: 'logo TGV' },
  'tgv duplex lyria': { src: logoLyria, alt: 'logo TGV' },
  'tgv duplex': { src: logoTgv, alt: 'logo TGV' },
  'tgv inoui': { src: logoTgvInoui, alt: 'logo TGV INOUI' },
  'tgv lyria': { src: logoLyria, alt: 'logo TGV Lyria' },
  tgv: { src: logoTgv, alt: 'logo TGV' },
  "tgv 100% prem's": { src: logoTgv, alt: 'logo TGV' },
  'talgo hotel-train': { src: logoRenfe, alt: 'logo Renfe' },
  renfe: { src: logoRenfe, alt: 'logo Renfe' },
  thalys: { src: logoThalys, alt: 'logo Thalys' },
  thalys_old: { src: logoThalys, alt: 'logo Thalys' },
  'train allegro': { src: logoRussianRailways, alt: 'logo Russian Railways' },
  'train eurocites': { src: logoEurocity, alt: 'logo Eurocity' },
  'train nomad': { src: logoTgvNomad, alt: 'logo TGV Nomad' },
  'train couchette': { src: logoIntercites, alt: 'logo Intercités' },
  train: { src: logoSncf, alt: 'logo SNCF' },
  tramtrain: { src: logoSncf, alt: 'logo SNCF' },
  trenitalia: { src: logoTrenitalia, alt: 'logo Trenitalia' },
  obb: { src: logoObb, alt: 'logo Obb' },
  nightjet: { src: logoNightjet, alt: 'logo Nightjet' },
  'voyages sncf': { src: logoSncf, alt: 'logo SNCF' },
  westBahn: { src: logoWebstbahn, alt: 'logo WestBahn' },
  'leo express': {
    src: logoLeoExpress,
    alt: 'logo Leo Express',
    style: {
      height: '4rem',
    },
  },
};

export const SNCF_BRANDS = [
  BRANDS.TER,
  BRANDS.TGV,
  BRANDS.TGVAtlantique,
  BRANDS.TGVDuplex,
  BRANDS.TGVInoui,
  BRANDS.CarTer,
  BRANDS.Intercite,
  BRANDS.InterciteNuit,
  BRANDS.Lyria,
];

export const OUIGO_BRANDS = [
  BRANDS.Ouigo,
  BRANDS['OUIGO Grande vitesse'],
  BRANDS['OUIGO Vitesse classique'],
];

export const IDENTITY_DOCUMENT_REQUIRED_BRANDS = [BRANDS.Renfe, BRANDS.IRYO];

export const ALTERNATIVE_BRANDS = [...SNCF_BRANDS, BRANDS.Eurostar];

export const ETransportMean = {
  TRAIN: 'train',
  BUS: 'bus',
  CARPOOLING: 'carpooling',
};

export const HTTP_METHODS = {
  POST: 'POST',
  PUT: 'PUT',
  GET: 'GET',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
};

export const HTTP_STATUS_CODES = {
  /**
   * The server has received the request headers and the client should proceed to send the request body
   * (in the case of a request for which a body needs to be sent; for example, a POST request).
   * Sending a large request body to a server after a request has been rejected for inappropriate headers would be inefficient.
   * To have a server check the request's headers, a client must send Expect: 100-continue as a header in its initial request
   * and receive a 100 Continue status code in response before sending the body. The response 417 Expectation Failed indicates the request should not be continued.
   */
  CONTINUE: 100,

  /**
   * The requester has asked the server to switch protocols and the server has agreed to do so.
   */
  SWITCHING_PROTOCOLS: 101,

  /**
   * A WebDAV request may contain many sub-requests involving file operations, requiring a long time to complete the request.
   * This code indicates that the server has received and is processing the request, but no response is available yet.
   * This prevents the client from timing out and assuming the request was lost.
   */
  PROCESSING: 102,

  /**
   * Standard response for successful HTTP requests.
   * The actual response will depend on the request method used.
   * In a GET request, the response will contain an entity corresponding to the requested resource.
   * In a POST request, the response will contain an entity describing or containing the result of the action.
   */
  OK: 200,

  /**
   * The request has been fulfilled, resulting in the creation of a new resource.
   */
  CREATED: 201,

  /**
   * The request has been accepted for processing, but the processing has not been completed.
   * The request might or might not be eventually acted upon, and may be disallowed when processing occurs.
   */
  ACCEPTED: 202,

  /**
   * SINCE HTTP/1.1
   * The server is a transforming proxy that received a 200 OK from its origin,
   * but is returning a modified version of the origin's response.
   */
  NON_AUTHORITATIVE_INFORMATION: 203,

  /**
   * The server successfully processed the request and is not returning any content.
   */
  NO_CONTENT: 204,

  /**
   * The server successfully processed the request, but is not returning any content.
   * Unlike a 204 response, this response requires that the requester reset the document view.
   */
  RESET_CONTENT: 205,

  /**
   * The server is delivering only part of the resource (byte serving) due to a range header sent by the client.
   * The range header is used by HTTP clients to enable resuming of interrupted downloads,
   * or split a download into multiple simultaneous streams.
   */
  PARTIAL_CONTENT: 206,

  /**
   * The message body that follows is an XML message and can contain a number of separate response codes,
   * depending on how many sub-requests were made.
   */
  MULTI_STATUS: 207,

  /**
   * The members of a DAV binding have already been enumerated in a preceding part of the (multistatus) response,
   * and are not being included again.
   */
  ALREADY_REPORTED: 208,

  /**
   * The server has fulfilled a request for the resource,
   * and the response is a representation of the result of one or more instance-manipulations applied to the current instance.
   */
  IM_USED: 226,

  /**
   * Indicates multiple options for the resource from which the client may choose (via agent-driven content negotiation).
   * For example, this code could be used to present multiple video format options,
   * to list files with different filename extensions, or to suggest word-sense disambiguation.
   */
  MULTIPLE_CHOICES: 300,

  /**
   * This and all future requests should be directed to the given URI.
   */
  MOVED_PERMANENTLY: 301,

  /**
   * This is an example of industry practice contradicting the standard.
   * The HTTP/1.0 specification (RFC 1945) required the client to perform a temporary redirect
   * (the original describing phrase was "Moved Temporarily"), but popular browsers implemented 302
   * with the functionality of a 303 See Other. Therefore, HTTP/1.1 added status codes 303 and 307
   * to distinguish between the two behaviours. However, some Web applications and frameworks
   * use the 302 status code as if it were the 303.
   */
  FOUND: 302,

  /**
   * SINCE HTTP/1.1
   * The response to the request can be found under another URI using a GET method.
   * When received in response to a POST (or PUT/DELETE), the client should presume that
   * the server has received the data and should issue a redirect with a separate GET message.
   */
  SEE_OTHER: 303,

  /**
   * Indicates that the resource has not been modified since the version specified by the request headers If-Modified-Since or If-None-Match.
   * In such case, there is no need to retransmit the resource since the client still has a previously-downloaded copy.
   */
  NOT_MODIFIED: 304,

  /**
   * SINCE HTTP/1.1
   * The requested resource is available only through a proxy, the address for which is provided in the response.
   * Many HTTP clients (such as Mozilla and Internet Explorer) do not correctly handle responses with this status code, primarily for security reasons.
   */
  USE_PROXY: 305,

  /**
   * No longer used. Originally meant "Subsequent requests should use the specified proxy."
   */
  SWITCH_PROXY: 306,

  /**
   * SINCE HTTP/1.1
   * In this case, the request should be repeated with another URI; however, future requests should still use the original URI.
   * In contrast to how 302 was historically implemented, the request method is not allowed to be changed when reissuing the original request.
   * For example, a POST request should be repeated using another POST request.
   */
  TEMPORARY_REDIRECT: 307,

  /**
   * The request and all future requests should be repeated using another URI.
   * 307 and 308 parallel the behaviors of 302 and 301, but do not allow the HTTP method to change.
   * So, for example, submitting a form to a permanently redirected resource may continue smoothly.
   */
  PERMANENT_REDIRECT: 308,

  /**
   * The server cannot or will not process the request due to an apparent client error
   * (e.g., malformed request syntax, too large size, invalid request message framing, or deceptive request routing).
   */
  BAD_REQUEST: 400,

  /**
   * Similar to 403 Forbidden, but specifically for use when authentication is required and has failed or has not yet
   * been provided. The response must include a WWW-Authenticate header field containing a challenge applicable to the
   * requested resource. See Basic access authentication and Digest access authentication. 401 semantically means
   * "unauthenticated",i.e. the user does not have the necessary credentials.
   */
  UNAUTHORIZED: 401,

  /**
   * Reserved for future use. The original intention was that this code might be used as part of some form of digital
   * cash or micro payment scheme, but that has not happened, and this code is not usually used.
   * Google Developers API uses this status if a particular developer has exceeded the daily limit on requests.
   */
  PAYMENT_REQUIRED: 402,

  /**
   * The request was valid, but the server is refusing action.
   * The user might not have the necessary permissions for a resource.
   */
  FORBIDDEN: 403,

  /**
   * The requested resource could not be found but may be available in the future.
   * Subsequent requests by the client are permissible.
   */
  NOT_FOUND: 404,

  /**
   * A request method is not supported for the requested resource;
   * for example, a GET request on a form that requires data to be presented via POST, or a PUT request on a read-only resource.
   */
  METHOD_NOT_ALLOWED: 405,

  /**
   * The requested resource is capable of generating only content not acceptable according to the Accept headers sent in the request.
   */
  NOT_ACCEPTABLE: 406,

  /**
   * The client must first authenticate itself with the proxy.
   */
  PROXY_AUTHENTICATION_REQUIRED: 407,

  /**
   * The server timed out waiting for the request.
   * According to HTTP specifications:
   * "The client did not produce a request within the time that the server was prepared to wait. The client MAY repeat the request without modifications at any later time."
   */
  REQUEST_TIMEOUT: 408,

  /**
   * Indicates that the request could not be processed because of conflict in the request,
   * such as an edit conflict between multiple simultaneous updates.
   */
  CONFLICT: 409,

  /**
   * Indicates that the resource requested is no longer available and will not be available again.
   * This should be used when a resource has been intentionally removed and the resource should be purged.
   * Upon receiving a 410 status code, the client should not request the resource in the future.
   * Clients such as search engines should remove the resource from their indices.
   * Most use cases do not require clients and search engines to purge the resource, and a "404 Not Found" may be used instead.
   */
  GONE: 410,

  /**
   * The request did not specify the length of its content, which is required by the requested resource.
   */
  LENGTH_REQUIRED: 411,

  /**
   * The server does not meet one of the preconditions that the requester put on the request.
   */
  PRECONDITION_FAILED: 412,

  /**
   * The request is larger than the server is willing or able to process. Previously called "Request Entity Too Large".
   */
  PAYLOAD_TOO_LARGE: 413,

  /**
   * The URI provided was too long for the server to process. Often the result of too much data being encoded as a query-string of a GET request,
   * in which case it should be converted to a POST request.
   * Called "Request-URI Too Long" previously.
   */
  URI_TOO_LONG: 414,

  /**
   * The request entity has a media type which the server or resource does not support.
   * For example, the client uploads an image as image/svg+xml, but the server requires that images use a different format.
   */
  UNSUPPORTED_MEDIA_TYPE: 415,

  /**
   * The client has asked for a portion of the file (byte serving), but the server cannot supply that portion.
   * For example, if the client asked for a part of the file that lies beyond the end of the file.
   * Called "Requested Range Not Satisfiable" previously.
   */
  RANGE_NOT_SATISFIABLE: 416,

  /**
   * The server cannot meet the requirements of the Expect request-header field.
   */
  EXPECTATION_FAILED: 417,

  /**
   * This code was defined in 1998 as one of the traditional IETF April Fools' jokes, in RFC 2324, Hyper Text Coffee Pot Control Protocol,
   * and is not expected to be implemented by actual HTTP servers. The RFC specifies this code should be returned by
   * teapots requested to brew coffee. This HTTP status is used as an Easter egg in some websites, including Google.com.
   */
  I_AM_A_TEAPOT: 418,

  /**
   * The request was directed at a server that is not able to produce a response (for example because a connection reuse).
   */
  MISDIRECTED_REQUEST: 421,

  /**
   * The request was well-formed but was unable to be followed due to semantic errors.
   */
  UNPROCESSABLE_ENTITY: 422,

  /**
   * The resource that is being accessed is locked.
   */
  LOCKED: 423,

  /**
   * The request failed due to failure of a previous request (e.g., a PROPPATCH).
   */
  FAILED_DEPENDENCY: 424,

  /**
   * The client should switch to a different protocol such as TLS/1.0, given in the Upgrade header field.
   */
  UPGRADE_REQUIRED: 426,

  /**
   * The origin server requires the request to be conditional.
   * Intended to prevent "the 'lost update' problem, where a client
   * GETs a resource's state, modifies it, and PUTs it back to the server,
   * when meanwhile a third party has modified the state on the server, leading to a conflict."
   */
  PRECONDITION_REQUIRED: 428,

  /**
   * The user has sent too many requests in a given amount of time. Intended for use with rate-limiting schemes.
   */
  TOO_MANY_REQUESTS: 429,

  /**
   * The server is unwilling to process the request because either an individual header field,
   * or all the header fields collectively, are too large.
   */
  REQUEST_HEADER_FIELDS_TOO_LARGE: 431,

  /**
   * A server operator has received a legal demand to deny access to a resource or to a set of resources
   * that includes the requested resource. The code 451 was chosen as a reference to the novel Fahrenheit 451.
   */
  UNAVAILABLE_FOR_LEGAL_REASONS: 451,

  /**
   * A generic error message, given when an unexpected condition was encountered and no more specific message is suitable.
   */
  INTERNAL_SERVER_ERROR: 500,

  /**
   * The server either does not recognize the request method, or it lacks the ability to fulfill the request.
   * Usually this implies future availability (e.g., a new feature of a web-service API).
   */
  NOT_IMPLEMENTED: 501,

  /**
   * The server was acting as a gateway or proxy and received an invalid response from the upstream server.
   */
  BAD_GATEWAY: 502,

  /**
   * The server is currently unavailable (because it is overloaded or down for maintenance).
   * Generally, this is a temporary state.
   */
  SERVICE_UNAVAILABLE: 503,

  /**
   * The server was acting as a gateway or proxy and did not receive a timely response from the upstream server.
   */
  GATEWAY_TIMEOUT: 504,

  /**
   * The server does not support the HTTP protocol version used in the request
   */
  HTTP_VERSION_NOT_SUPPORTED: 505,

  /**
   * Transparent content negotiation for the request results in a circular reference.
   */
  VARIANT_ALSO_NEGOTIATES: 506,

  /**
   * The server is unable to store the representation needed to complete the request.
   */
  INSUFFICIENT_STORAGE: 507,

  /**
   * The server detected an infinite loop while processing the request.
   */
  LOOP_DETECTED: 508,

  /**
   * Further extensions to the request are required for the server to fulfill it.
   */
  NOT_EXTENDED: 510,

  /**
   * The client needs to authenticate to gain network access.
   * Intended for use by intercepting proxies used to control access to the network (e.g., "captive portals" used
   * to require agreement to Terms of Service before granting full Internet access via a Wi-Fi hotspot).
   */
  NETWORK_AUTHENTICATION_REQUIRED: 511,
};

export const HTTP = {
  METHODS: { ...HTTP_METHODS },
  STATUS_CODES: { ...HTTP_STATUS_CODES },
};
