import { lazy, Suspense } from 'react';
import LazyLoading from '../../components/LazyLoading';

export const Lazy = lazy(() =>
  import(/* webpackChunkName: 'VerifyMagicLink' */ './index'),
);

export default function Login(props) {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Lazy {...props} />
    </Suspense>
  );
}
