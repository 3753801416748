import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { NAVIGATION } from '../../containers/App/constants';
import { Sentry } from '../../libs/sentry';
import history from '../../utils/history';
import request from '../../utils/request';
import {
  clickIdToReferer,
  PARTNER_ID_URL_PARAMETER,
  URL_GET_PARTNER,
} from './constants';
import { EFunnelStep, IAppContext, IPartner } from './types';

export const AppContext = createContext<IAppContext>({
  partner: null,
  funnelStep: EFunnelStep.NONE,
  isInFunnel: false,
  referer: null,
  setRefererId: () => false,
});

interface AppProviderProps {
  children: React.ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [partner, setPartner] = useState<IPartner | null>(null);
  const [funnelStep, setFunnelStep] = useState<EFunnelStep>(EFunnelStep.NONE);
  const [referer, setReferer] = useState<string | null>(null);

  const urlParameters: string = history.location.search;
  const urlPathname: string = history.location.pathname;

  // Update the partnerId if it is found in the URL
  useEffect(() => {
    try {
      const loadPartner = async (partnerId: string) => {
        try {
          const partner = await request(`${URL_GET_PARTNER}/${partnerId}`);
          setPartner(partner);
        } catch (error) {
          return;
        } finally {
          setIsLoading(false);
        }
      };

      const parameters = new URLSearchParams(urlParameters);
      const newPartnerId = parameters.get(PARTNER_ID_URL_PARAMETER);

      const clickIdKey = Object.keys(clickIdToReferer).find((key) =>
        parameters.has(key),
      );

      const newReferer = clickIdToReferer[clickIdKey];

      if (!newPartnerId && !newReferer) {
        setIsLoading(false);
        return;
      }

      if (newReferer) {
        setReferer(newReferer);
      }

      if (newPartnerId) {
        loadPartner(newPartnerId);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);

      if (!error.capturedBySentry) {
        Sentry.captureException(error);
      }
    }
  }, [urlParameters]);

  const handleSetRefererId = useCallback((refererId: string): boolean => {
    const refererExists = Object.values(clickIdToReferer).includes(refererId);

    if (!refererExists) {
      return false;
    }

    setReferer(refererId);
    return true;
  }, []);

  // Update the funnel step according to the page url
  useEffect(() => {
    const slug = urlPathname.split('/')[1];
    switch (`/${slug}`) {
      case NAVIGATION.RESULTS:
        setFunnelStep(EFunnelStep.RESULTS);
        break;
      case NAVIGATION.INFO:
        setFunnelStep(EFunnelStep.INFO);
        break;
      case NAVIGATION.PAYMENT:
        setFunnelStep(EFunnelStep.PAYMENT);
        break;
      default:
        setFunnelStep(EFunnelStep.NONE);
        break;
    }
  }, [urlPathname]);

  const value: IAppContext = useMemo(
    () => ({
      partner,
      funnelStep,
      referer,
      isInFunnel: funnelStep !== EFunnelStep.NONE,
      isLoading,
      setRefererId: handleSetRefererId,
    }),
    [funnelStep, handleSetRefererId, isLoading, partner, referer],
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useApp = (): IAppContext => {
  const context = useContext(AppContext);

  if (!context) {
  }

  return context;
};
