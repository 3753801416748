import { EPassengerCategory } from '../../common/types';
import {
  IAutoComplete,
  IPassenger,
  IPopularCity,
  ISearchCity,
  ISearchParameters,
  searchParamsSchema,
} from './types';

/**
 * @description Returns a formatted passenger object from a url
 * @param {string} passengersToFormat The passengers string from the URL to format
 * @returns {unknown[] | null}
 */
const formatPassengers = (passengersToFormat: string): unknown[] | null => {
  const passengers = JSON.parse(passengersToFormat);

  if (!Array.isArray(passengers)) {
    return null;
  }

  const formattedPassengers: unknown[] = [];

  for (const passenger of passengers) {
    const formattedPassenger = JSON.parse(passenger);

    formattedPassengers.push(formattedPassenger);
  }

  return formattedPassengers;
};

/**
 * @description Returns an object containing the search parameters of an url if it is valid
 * @param {string} url The url where we want to get the parameters
 * @returns {ISearchParameters | null}
 */
export const getSearchParametersFromUrl = (
  url: string,
): ISearchParameters | null => {
  const parameters = new URLSearchParams(url);
  const originId = Number(parameters.get('originId'));
  const destinationId = Number(parameters.get('destinationId'));
  const outboundTime = Number(parameters.get('outboundTime'));
  const returnTime = Number(parameters.get('returnTime'));
  const refererId = parameters.get('refererId');

  const formattedSearchParameters = {
    origin: parameters.get('origin'),
    originId: isNaN(originId) ? null : originId,
    destination: parameters.get('destination'),
    destinationId: isNaN(destinationId) ? null : destinationId,
    outboundDate: parameters.get('outboundDate'),
    outboundTime: isNaN(outboundTime) ? null : outboundTime,
    returnDate: parameters.get('returnDate') || null,
    returnTime: isNaN(returnTime) ? null : returnTime,
    passengers: formatPassengers(parameters.get('passengers')),
    refererId,
  };
  try {
    return searchParamsSchema.validateSync(
      formattedSearchParameters,
    ) as ISearchParameters;
  } catch (error) {
    return null;
  }
};

export const formatPopularCities = (cities: IPopularCity[]): ISearchCity[] => {
  const formattedCities: ISearchCity[] = [];

  for (const city of cities) {
    const [cityName, cityRegion, cityCountry] = city.local_name.split(', ');

    const newFormattedCity: ISearchCity = {
      id: city.id,
      gpuid: city.gpuid,
      name: cityName,
      region: cityRegion,
      country: cityCountry,
      stations: [],
      nearFrom: null,
    };

    formattedCities.push(newFormattedCity);
  }

  return formattedCities;
};

export const formatAutoCompleteComCities = (
  cities: IAutoComplete[],
): ISearchCity[] => {
  const formattedCities: ISearchCity[] = [];

  cities.forEach((city) => {
    const index: number = formattedCities.findIndex(
      (formattedCity) => formattedCity.id === city.city_id,
    );

    let formattedCity: ISearchCity = formattedCities[index];

    const alreadyAdded = index >= 0;

    if (!alreadyAdded) {
      const [cityName, cityRegion, cityCountry] = city.local_name.split(', ');

      formattedCity = {
        id: city.city_id,
        gpuid: city.gpuid,
        name: cityName,
        region: cityRegion,
        country: cityCountry,
        stations: [],
        nearFrom:
          city.nearfrom && city.distance >= 0
            ? {
                name: city.nearfrom,
                distance: city.distance,
              }
            : null,
      };
    }

    if (city.station_id && city.station_id > 0) {
      formattedCity.stations.push({
        id: city.station_id,
        name: city.station_unique_name,
      });
    }

    if (alreadyAdded) {
      formattedCities[index] = formattedCity;
    } else {
      formattedCities.push(formattedCity);
    }
  });

  return formattedCities;
};

/**
 * @description Returns the number of passengers per category
 * @param {IPassenger[]} passengers
 * @returns {{[value in EPassengerCategory]: number;}}
 */
export const getPassengersCountPerCategory = (
  passengers: IPassenger[],
): {
  [value in EPassengerCategory]: number;
} => {
  const passengersCount: { [value in EPassengerCategory] } = {
    [EPassengerCategory.ADULT]: 0,
    [EPassengerCategory.YOUNG]: 0,
    [EPassengerCategory.SENIOR]: 0,
  };

  for (const passenger of passengers) {
    passengersCount[passenger.category] += 1;
  }

  return passengersCount;
};

export const sortPassengersByCategory = (
  passengers: IPassenger[],
): {
  [value in EPassengerCategory]: IPassenger[];
} => {
  const sortedPassengers: {
    [value in EPassengerCategory]: IPassenger[];
  } = {
    [EPassengerCategory.ADULT]: [],
    [EPassengerCategory.SENIOR]: [],
    [EPassengerCategory.YOUNG]: [],
  };

  for (const passenger of passengers) {
    sortedPassengers[passenger.category] = [
      ...sortedPassengers[passenger.category],
      passenger,
    ];
  }

  return sortedPassengers;
};
