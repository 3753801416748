import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { NewModal } from '../NewModal';
import { checkMaintenance } from './utils';
import { MaintenanceView } from './View/MaintenanceView';

export const POLLING_INTERVAL = 5000;

/**
 * @description Maintenance Component
 * @param {function()} setIsSystemUnderMaintenance
 */
export const Maintenance = ({ setIsSystemUnderMaintenance }) => {
  // Start polling while the API is in maintenance
  useEffect(() => {
    const intervalId = setInterval(async () => {
      const isUnderMaintenance = await checkMaintenance();

      setIsSystemUnderMaintenance(isUnderMaintenance);
      if (!isUnderMaintenance) clearInterval(intervalId);
    }, POLLING_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [setIsSystemUnderMaintenance]);

  return <NewModal header={''} isOpen main={<MaintenanceView />} />;
};

Maintenance.propTypes = {
  setIsSystemUnderMaintenance: PropTypes.func.isRequired,
};
