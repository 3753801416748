import { isValidPhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';
import { EIsoCountryCodes } from '../../../../common/iso-country-codes';
import {
  EIdentityDocumentType,
  EPassengerTitle,
} from '../../../../common/types';
import {
  isPassengerBirthDateValid,
  isPassengerIdendityDocumentExpirationDateValid,
} from '../../../../hooks/usePassengersManagement/utils';
import { DISCOUNT_CARD_INPUT_SEPARATOR } from '../../constants';

export const FORM_VALIDATION_ERROR = 'form.validation.error';
export const FORM_VALIDATION_REQUIRED_FIELD = 'form.validation.required';
export const FORM_VALIDATION_INVALID_DATE = 'form.validation.date.invalid';
export const FORM_VALIDATION_INVALID_EXPIRATION_DATE =
  'form.validation.indetityDocument.expirationDate.invalid';
export const FORM_VALIDATION_INVALID_EMAIL = 'form.validation.email.invalid';

const IdentityDocumentSchema = yup.object().shape({
  countryCode: yup
    .string()

    .required(FORM_VALIDATION_REQUIRED_FIELD)
    .oneOf(Object.values(EIsoCountryCodes)),
  documentIdentifier: yup
    .string()
    .required(FORM_VALIDATION_REQUIRED_FIELD)
    .min(3)
    .max(20),
  expirationDate: yup
    .string()
    .required(FORM_VALIDATION_REQUIRED_FIELD)
    .test('Invalid Date', FORM_VALIDATION_INVALID_EXPIRATION_DATE, (value) =>
      isPassengerIdendityDocumentExpirationDateValid(value),
    ),
  type: yup
    .string()
    .required(FORM_VALIDATION_REQUIRED_FIELD)
    .oneOf(Object.values(EIdentityDocumentType)),
});

export const FormPassengerSchema = yup.object().shape({
  birthdate: yup
    .string()
    .required(FORM_VALIDATION_REQUIRED_FIELD)
    .test('Invalid Date', FORM_VALIDATION_INVALID_DATE, (value) =>
      isPassengerBirthDateValid(value),
    ),
  discountCardNumber: yup
    .string()
    .test('Invalid discount card number format', '', (value) => {
      if (!value) {
        return true;
      }

      return /^[0-9]{17}$/.test(
        value.replaceAll(DISCOUNT_CARD_INPUT_SEPARATOR, ''),
      );
    })
    .nullable(),
  firstName: yup.string().required(FORM_VALIDATION_REQUIRED_FIELD),
  identityDocument: IdentityDocumentSchema.default(null).nullable(),
  lastName: yup.string().required(FORM_VALIDATION_REQUIRED_FIELD),
  title: yup
    .string()
    .required(FORM_VALIDATION_REQUIRED_FIELD)
    .oneOf(Object.values(EPassengerTitle)),
});

const isEmailValid = (email: string): boolean => {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
};

export const FormCustomerSchema = yup.object().shape({
  birthdate: yup
    .string()
    .required(FORM_VALIDATION_REQUIRED_FIELD)
    .test('Invalid Date', FORM_VALIDATION_INVALID_DATE, (value) =>
      isPassengerBirthDateValid(value),
    ),
  firstName: yup.string().required(FORM_VALIDATION_REQUIRED_FIELD),
  lastName: yup.string().required(FORM_VALIDATION_REQUIRED_FIELD),
  mail: yup
    .string()
    .email()
    .test('Invalid Email format', FORM_VALIDATION_INVALID_EMAIL, (value) =>
      isEmailValid(value),
    )
    .required(FORM_VALIDATION_REQUIRED_FIELD),
  phoneNumber: yup
    .string()
    .test('Invalid Phone Number format', '', (value) => {
      if (!value) {
        return true;
      }

      return isValidPhoneNumber(value);
    })
    .nullable(),
  title: yup
    .string()
    .oneOf(Object.values(EPassengerTitle))
    .required(FORM_VALIDATION_REQUIRED_FIELD),
});

export const FormSchema = yup.object({
  passengers: yup.array().of(FormPassengerSchema).min(1),
  customer: FormCustomerSchema,
});
