import '../../styles/phone_number.css';
import React, { useRef, useState } from 'react';
import {
  FormattedHTMLMessage,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import PhoneInput from 'react-phone-number-input';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import SubmitButton from '../../components/ButtonSubmit';
import {
  COUNTRIES_FOR_PHONE,
  COUNTRY_LABELS_FOR_PHONES,
} from '../../components/Forms/Input/AnimatedPhoneField';
import Circles from '../../components/FunnelHeader/Circles';
import H2 from '../../components/H2';
import Input, { InputStyleValue } from '../../components/Input';
import Row from '../../components/Row';
import { colors } from '../../styles/constants';
import { TYPE_OF_REQUEST_FIELD } from './constants';
import messages from './messages';
import { handleFormSubmit } from './utils';
import { FormRow, InputTitle } from './views';
import { FileUploader } from './views/FileUploader';
import { SuccessModal } from './views/modals';
import { SubjectFields } from './views/SubjectFields';

const Background = styled.div`
  ${tw`relative
    w-full
    pt-def
    text-white
    sm:pt-abs3`};
  background-color: ${colors.terciary500};
  height: 10rem;
`;

const Header = () => (
  <Background>
    <Circles />
  </Background>
);

const MainContainer = styled(Row)`
  ${tw`px-abs2`}
`;

const Title = styled(H2)`
  ${tw`w-auto px-0`}
  text-align: start;
`;

const CustomInput = styled(Input)`
  ${tw`md:max-w-abs1 mt-abs1`}
`;

const CustomTextArea = styled.textarea`
  ${InputStyleValue}
  ${tw`md:max-w-abs1 mt-abs1 py-def px-def `}
  min-height:12rem;
`;

const SubmitFormButton = styled(SubmitButton)`
  ${tw`px-abs7 bg-secondary400 hover:bg-secondary400 `}
`;

const Support = ({ intl }) => {
  const [files, setFiles] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(0);

  const [phoneNumber, setPhoneNumber] = useState('');
  const phoneNumberRef = useRef(null);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const query = new URLSearchParams(window.location.search);

  return (
    <React.Fragment>
      <Header />
      <MainContainer>
        <Title>
          <FormattedHTMLMessage {...messages.pageTitle} />
        </Title>

        <SuccessModal intl={intl} isOpen={isSubmitted} />
        <form
          onSubmit={async (e) => {
            e.preventDefault();

            if (!phoneNumber || phoneNumber === '') {
              phoneNumberRef.current.focus();
              return;
            }

            setIsLoading(true);
            const hasBeenSubmitted = await handleFormSubmit(
              e,
              files,
              phoneNumber,
              {
                message: intl.formatMessage(
                  messages[
                    `${TYPE_OF_REQUEST_FIELD[selectedSubject].messageId}`
                  ],
                ),
                typeOfRequest:
                  TYPE_OF_REQUEST_FIELD[selectedSubject].typeOfRequestOptionId,
              },
            );

            setIsSubmitted(hasBeenSubmitted);
            setIsLoading(false);
          }}
          style={{ maxWidth: '60rem' }}
        >
          <FormRow>
            <label>
              <InputTitle>
                <FormattedMessage {...messages.emailFieldLabel} />
              </InputTitle>
              <CustomInput
                defaultValue={query.get('prefill_mail')}
                name="email"
                placeholder="Mike.Horn@gmail.com"
                required
                type="email"
              />
            </label>
          </FormRow>

          <FormRow>
            <label>
              <InputTitle>
                <FormattedMessage {...messages.phoneFieldLabel} />
              </InputTitle>

              <PhoneInput
                ref={phoneNumberRef}
                addInternationalOption={false}
                countries={COUNTRIES_FOR_PHONE}
                defaultCountry={'FR'}
                id="phoneNumberInput"
                labels={COUNTRY_LABELS_FOR_PHONES}
                maxLength="15"
                onChange={(e) => setPhoneNumber(e)}
                placeholder={'06 12 34 56 78'}
                style={{ width: '100%' }}
                value={phoneNumber}
              />
            </label>
          </FormRow>

          <FormRow>
            <label>
              <InputTitle>
                <FormattedMessage {...messages.orderIdFieldLabel} />
              </InputTitle>
              <CustomInput
                defaultValue={query.get('prefill_orderId')}
                maxLength={16}
                minLength={16}
                name="orderId"
                placeholder={intl.formatMessage(messages.orderIdPlaceHolder)}
                required
                type={'text'}
              />
            </label>
          </FormRow>

          <FormRow>
            <label>
              <InputTitle>
                <FormattedMessage {...messages.departureDateFieldLabel} />
              </InputTitle>
              <CustomInput
                defaultValue={query.get('prefill_outboundDate')}
                name="departureDate"
                required
                type={'date'}
              />
            </label>
          </FormRow>

          <SubjectFields
            selectedSubject={selectedSubject}
            setSelectedSubject={setSelectedSubject}
          />

          <FormRow>
            <label>
              <InputTitle>
                <FormattedMessage {...messages.moreInformations} />
              </InputTitle>
            </label>

            <CustomTextArea
              name="moreInformations"
              placeholder={intl.formatMessage(
                messages.moreInformationsPlaceholder,
              )}
              required
            />
          </FormRow>

          <FormRow>
            <label>
              <InputTitle>
                <FormattedMessage {...messages.attachImageFieldLabel} />
              </InputTitle>
            </label>
          </FormRow>

          <FormRow>
            <label>
              <FileUploader files={files} setFiles={setFiles} />
            </label>
          </FormRow>

          <SubmitFormButton isLoading={isLoading}>
            {' '}
            <FormattedMessage {...messages.send} />
          </SubmitFormButton>
        </form>
      </MainContainer>
    </React.Fragment>
  );
};

Support.propTypes = {
  intl: intlShape.isRequired,
};

const SupportIntl = injectIntl(Support);

export default SupportIntl;
