import React from 'react';
import styled from 'styled-components';
import { IconProps } from './types';

const SVG = styled.svg<IconProps>`
  ${({ customStyle }) => customStyle}
`;

export const ChatIcon: React.FC<IconProps> = ({ color, customStyle }) => (
  <SVG
    customStyle={customStyle}
    fill="none"
    height="23"
    viewBox="0 0 22 23"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.57 16.62L8.5 15.5C12.64 15.5 16 12.37 16 8.5C16 7.45 15.75 6.46 15.3 5.57M7.57 16.62L7.02 17.28C6.55 17.84 5.65 17.72 5.34 17.05L4 14.1C2.18 12.82 1 10.79 1 8.5C1 4.63 4.36 1.5 8.5 1.5C11.52 1.5 14.13 3.17 15.3 5.57M7.57 16.62C8.92 18.29 11.08 19.36 13.5 19.36L14.98 21.14C15.45 21.71 16.35 21.58 16.66 20.91L18 17.96C19.82 16.68 21 14.65 21 12.36C21 9.08 18.57 6.32 15.3 5.57M6 8.5H11"
      stroke={color ?? 'white'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </SVG>
);
