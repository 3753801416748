import React from 'react';
import PhoneInput from 'react-phone-number-input';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { InputStyle } from '.';
import { Container, FieldStyle, Placeholder } from './AnimatedTextField';

export const COUNTRIES_FOR_PHONE = [
  'FR',
  'ES',
  'GB',
  'IT',
  'BE',
  'LU',
  'CH',
  'DE',
  'PT',
  'NL',
];

export const COUNTRY_LABELS_FOR_PHONES = {
  FR: 'France',
  ES: 'Espagne',
  GB: 'Royaume-Uni',
  IT: 'Italie',
  BE: 'Belgique',
  LU: 'Luxembourg',
  CH: 'Suisse',
  DE: 'Allemagne',
  PT: 'Portugal',
  NL: 'Pays-Bas',
};

const FieldWrapper = styled.div`
  input {
    ${FieldStyle}
    ${tw`px-0`}
  }

  .PhoneInput {
    ${tw`border-none`}
    ${InputStyle}
  }
`;
const CustomPlaceholder = styled(Placeholder)`
  left: 80px;
`;

type AnimatedPhoneFieldProps = {
  onChange: (phoneNumber: string) => void;
  value: string;
  placeholder?: string;
  placeHolderLabel: React.ReactNode;
  inputId?: string;
  id?: string;
  name?: string;
};

const AnimatedPhoneField = ({
  id,
  inputId,
  name,
  onChange,
  placeholder,
  placeHolderLabel,
  value,
}: AnimatedPhoneFieldProps) => (
  <Container id={id}>
    <FieldWrapper>
      {/* @ts-ignore */}
      <PhoneInput
        addInternationalOption={false}
        countries={COUNTRIES_FOR_PHONE as any}
        defaultCountry={'FR'}
        id={inputId}
        labels={COUNTRY_LABELS_FOR_PHONES}
        maxLength="15"
        name={name}
        onChange={onChange}
        onFocus={(event) => (event.target as HTMLInputElement).select()}
        placeholder={placeholder}
        style={{ width: '100%' }}
        value={value}
      />
    </FieldWrapper>
    <CustomPlaceholder isFilled={!!value}>{placeHolderLabel}</CustomPlaceholder>
  </Container>
);

export default AnimatedPhoneField;
