import { defineMessages } from 'react-intl';

export const loginScope = 'app.containers.login';

export const loginMessages = {
  //Errors :
  genericError: {
    id: `${loginScope}.genericError`,
    defaultMessage:
      "Une erreur s'est produite lors de la tentative de connexion. Veuillez réessayer plus tard.",
    en: 'An error occurred while trying to connect. Please try again later.',
    es: 'Se produjo un error al intentar conectarse. Por favor, inténtelo de nuevo más tarde.',
    it: 'Si è verificato un errore durante il tentativo di connessione. Riprova più tardi.',
    de: 'Bei dem Verbindungsversuch ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
    nl: 'Er is een fout opgetreden bij het verbinden. Probeer het later opnieuw.',
  },
  noMatchingReservationFoundError: {
    id: `${loginScope}.noMatchingReservationFoundError`,
    defaultMessage:
      "Aucune réservation correspondante n'a été trouvée pour cet email. Veuillez vérifier vos informations.",
    en: 'No matching reservation found for this email. Please check your details.',
    es: 'No se encontró ninguna reserva correspondiente para este correo electrónico. Por favor, verifique su información.',
    it: 'Nessuna prenotazione corrispondente trovata per questa email. Si prega di verificare i dati.',
    de: 'Keine entsprechende Buchung für diese E-Mail gefunden. Bitte überprüfen Sie Ihre Angaben.',
    nl: 'Geen bijpassende reservering gevonden voor dit e-mailadres. Controleer uw gegevens.',
  },
  emailNotAssociatedError: {
    id: `${loginScope}.emailNotAssociatedError`,
    defaultMessage:
      "Cet email n'est associé à aucun compte. Veuillez vérifier vos informations.",
    en: 'This email is not associated with any account. Please check your details.',
    es: 'Este correo electrónico no está asociado a ninguna cuenta. Por favor, verifique su información.',
    it: 'Questa email non è associata a nessun account. Si prega di verificare i dati.',
    de: 'Diese E-Mail ist mit keinem Konto verbunden. Bitte überprüfen Sie Ihre Angaben.',
    nl: 'Dit e-mailadres is niet gekoppeld aan een account. Controleer uw gegevens.',
  },

  loginToYourAccount: {
    id: `${loginScope}.loginToYourAccount`,
    defaultMessage: 'Se connecter à votre compte',
    en: 'Login to your account',
    es: 'Iniciar sesión en su cuenta',
    it: 'Accedi al tuo account',
    de: 'Melden Sie sich bei Ihrem Konto an',
    nl: 'Log in op uw account',
  },
  accountAccessRestricted: {
    id: `${loginScope}.accountAccessRestricted`,
    defaultMessage:
      "L'accès au compte est réservé aux voyageurs. Si vous n'avez pas de réservation, impossible (pour l'instant) de vous connecter.",
    en: "Account access is restricted to travelers. If you don't have a reservation, it's not possible to log in (for now).",
    es: 'El acceso a la cuenta está reservado para viajeros. Si no tiene una reserva, no es posible iniciar sesión (por ahora).',
    it: "L'accesso all'account è riservato ai viaggiatori. Se non hai una prenotazione, non è possibile accedere (per ora).",
    de: 'Der Kontozugang ist Reisenden vorbehalten. Wenn Sie keine Reservierung haben, ist es momentan nicht möglich, sich anzumelden.',
    nl: 'Toegang tot het account is voorbehouden aan reizigers. Als u geen reservering heeft, is inloggen momenteel niet mogelijk.',
  },
  or: {
    id: `${loginScope}.or`,
    defaultMessage: 'OU',
    en: 'OR',
    es: 'O',
    it: 'O',
    de: 'ODER',
    nl: 'OF',
  },
  loginWithEmail: {
    id: `${loginScope}.loginWithEmail`,
    defaultMessage: 'Se connecter avec un e-mail',
    en: 'Log in with an email',
    es: 'Iniciar sesión con un correo electrónico',
    it: "Accedi con un'email",
    de: 'Mit einer E-Mail einloggen',
    nl: 'Inloggen met een e-mail',
  },
  loginWithBookingNumber: {
    id: `${loginScope}.loginWithBookingNumber`,
    defaultMessage: 'Se connecter avec un numéro de réservation',
    en: 'Log in with a booking number',
    es: 'Iniciar sesión con un número de reserva',
    it: 'Accedi con un numero di prenotazione',
    de: 'Mit einer Buchungsnummer einloggen',
    nl: 'Inloggen met een boekingsnummer',
  },

  bookingEmailAddress: {
    id: `${loginScope}.bookingEmailAddress`,
    defaultMessage: 'Adresse e-mail de réservation',
    en: 'Booking email address',
    es: 'Correo electrónico de reserva',
    it: 'Indirizzo email di prenotazione',
    de: 'Buchungs-E-Mail-Adresse',
    nl: 'Boekings e-mailadres',
  },
  bookingNumber: {
    id: `${loginScope}.bookingNumber`,
    defaultMessage: 'Numéro de votre réservation',
    en: 'Your booking number',
    es: 'Su número de reserva',
    it: 'Il numero della tua prenotazione',
    de: 'Ihre Buchungsnummer',
    nl: 'Uw boekingsnummer',
  },
  howToFindBookingNumber: {
    id: `${loginScope}.howToFindBookingNumber`,
    defaultMessage: 'Comment trouver un numéro de réservation ?',
    en: 'How to find a booking number?',
    es: '¿Cómo encontrar un número de reserva?',
    it: 'Come trovare un numero di prenotazione?',
    de: 'Wie finde ich eine Buchungsnummer?',
    nl: 'Hoe vind je een boekingsnummer?',
  },
  accessYourAccount: {
    id: `${loginScope}.accessYourAccount`,
    defaultMessage: 'Accéder à votre compte',
    en: 'Access your account',
    es: 'Accede a tu cuenta',
    it: 'Accedi al tuo account',
    de: 'Greifen Sie auf Ihr Konto zu',
    nl: 'Toegang tot uw account',
  },
  sendMagicLink: {
    id: `${loginScope}.sendMagicLink`,
    defaultMessage: 'Recevoir un lien de connexion',
    en: 'Receive a login link',
    es: 'Recibir un enlace de inicio de sesión',
    it: 'Ricevi un link di accesso',
    de: 'Login-Link erhalten',
    nl: 'Ontvang een inloglink',
  },

  magicLinkSuccessModalTitle: {
    id: `${loginScope}.magicLinkSuccessModalTitle`,
    defaultMessage: 'Votre lien de connexion est en route !',
    en: 'Your connection link is on the way!',
    es: '¡Tu enlace de conexión está en camino!',
    it: 'Il tuo link di accesso è in arrivo!',
    de: 'Ihr Verbindungslink ist unterwegs!',
    nl: 'Je verbindingslink is onderweg!',
  },
  magicLinkSuccessModalText: {
    id: `${loginScope}.magicLinkSuccessModalText`,
    defaultMessage:
      "Un lien de connexion vient d'être envoyé à votre adresse e-mail. Suivez les instructions pour accéder à votre compte.",
    en: 'A connection link has been sent to your email address. Follow the instructions to access your account.',
    es: 'Se ha enviado un enlace de conexión a tu dirección de correo electrónico. Sigue las instrucciones para acceder a tu cuenta.',
    it: 'Un link di accesso è stato inviato al tuo indirizzo e-mail. Segui le istruzioni per accedere al tuo account.',
    de: 'Ein Verbindungslink wurde an Ihre E-Mail-Adresse gesendet. Folgen Sie den Anweisungen, um auf Ihr Konto zuzugreifen.',
    nl: 'Een verbindingslink is naar je e-mailadres gestuurd. Volg de instructies om toegang te krijgen tot je account.',
  },

  havingAccountIsGreat: {
    id: `${loginScope}.havingAccountIsGreat`,
    defaultMessage: "Avoir un compte, c'est trop génial.",
    en: 'Having an account is awesome.',
    es: 'Tener una cuenta es genial.',
    it: 'Avere un account è fantastico.',
    de: 'Ein Konto zu haben ist großartig.',
    nl: 'Een account hebben is geweldig.',
  },
  allReservationsAvailable: {
    id: `${loginScope}.allReservationsAvailable`,
    defaultMessage:
      'Toutes vos réservations à venir et passées sont disponibles.',
    en: 'All your upcoming and past reservations are available.',
    es: 'Todas tus reservas futuras y pasadas están disponibles.',
    it: 'Tutte le tue prenotazioni future e passate sono disponibili.',
    de: 'Alle Ihre bevorstehenden und vergangenen Reservierungen sind verfügbar.',
    nl: 'Al je aankomende en afgelopen reserveringen zijn beschikbaar.',
  },
  earlyDealAlerts: {
    id: `${loginScope}.earlyDealAlerts`,
    defaultMessage: 'Vous recevez les alertes bons plans en avant première.',
    en: 'You receive early deal alerts.',
    es: 'Recibes alertas de ofertas anticipadas.',
    it: 'Ricevi avvisi di offerte anticipate.',
    de: 'Sie erhalten Frühbucher-Angebotsbenachrichtigungen.',
    nl: 'Je ontvangt vroegtijdige dealmeldingen.',
  },
  yourOwnAccount: {
    id: `${loginScope}.yourOwnAccount`,
    defaultMessage: "C'est votre compte, rien que le vôtre et c'est tout.",
    en: "It's your account, just yours and that's it.",
    es: 'Es tu cuenta, solo tuya y eso es todo.',
    it: 'È il tuo account, solo tuo e basta.',
    de: "Es ist Ihr Konto, nur Ihres und das war's.",
    nl: 'Het is jouw account, alleen van jou en dat is het.',
  },

  accountAndReservations: {
    id: `${loginScope}.accountAndReservations`,
    defaultMessage: 'Compte et réservations',
    en: 'Account and reservations',
    es: 'Cuenta y reservas',
    it: 'Account e prenotazioni',
    de: 'Konto und Reservierungen',
    nl: 'Account en reserveringen',
  },
};

export default defineMessages(loginMessages);
