import React from 'react';
import styled from 'styled-components';
import { IconProps } from './types';

const SVG = styled.svg<IconProps>`
  ${({ customStyle }) => customStyle}
`;

export const MenuIcon: React.FC<IconProps> = ({ color, customStyle }) => (
  <SVG
    customStyle={customStyle}
    fill="none"
    height="16"
    viewBox="0 0 18 16"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.25 7.92418H11.25M2.25 3.96191H15.75M2.25 11.8864H15.75"
      stroke={color ?? 'white'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </SVG>
);
