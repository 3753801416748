import { lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import LazyLoading from '../../components/LazyLoading';

export const LazyCompany = lazy(() =>
  import(/* webpackChunkName: 'Company' */ './index'),
);

export default function Company(props) {
  const { id } = useParams();

  return (
    <Suspense fallback={<LazyLoading />}>
      <LazyCompany {...props} key={id} />
    </Suspense>
  );
}
