import React from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

interface IconProps {
  color?: string;
  customStyle?: FlattenSimpleInterpolation;
}

const SVG = styled.svg<IconProps>`
  ${({ customStyle }) => customStyle}
`;

export const LeafIcon: React.FC<IconProps> = ({ color, customStyle }) => (
  <SVG
    customStyle={customStyle}
    fill="none"
    height="18"
    viewBox="0 0 19 18"
    width="19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2031 9.77744C14.3642 8.31886 14.0234 7.08405 12.964 5.96169C11.9565 4.89436 10.9567 4.77794 9.55341 5.01777C8.04662 5.27528 6.89161 5.53806 5.45504 4.8347C4.77752 4.50298 3.98439 3.95037 3.56218 3.31298C3.36523 3.01567 3.15291 1.96596 3.15291 2.32329V4.1711C3.15291 5.19257 2.85838 6.20037 2.84596 7.22598C2.83436 8.18292 2.87195 9.15329 3.15291 10.076C3.44139 11.0234 4.18251 12.2982 4.88464 12.9593C6.19996 14.1976 8.30797 15.1258 10.2128 14.8003C11.1857 14.634 13.091 13.3938 13.3441 12.375M16.25 15.75C16.25 15 15.9184 14.5248 15.8407 14.3885C15.3887 13.5955 14.796 13.0187 14.1013 12.432C12.8659 11.3884 11.3506 10.6013 9.70171 10.6013"
      stroke={color ?? 'white'}
      strokeLinecap="round"
      strokeWidth="2"
    />
  </SVG>
);
