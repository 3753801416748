import '../../animations/CSSTransition/fadeIn.css';
import { CSSTransition } from 'react-transition-group';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import tw from 'tailwind.macro';

const DrawerOverlayBackground = styled.div<{
  customStyle?: FlattenSimpleInterpolation;
}>`
  ${tw`bg-overlay fixed`}
  inset: 0;

  ${({ customStyle }) => customStyle}
  backdrop-filter: blur(2px);
`;

type DrawerOverlayProps = {
  isOpen: boolean;
  onClose: () => void;
  customStyle?: FlattenSimpleInterpolation;
};

export const DrawerOverlay = (props: DrawerOverlayProps) => {
  return (
    <CSSTransition
      classNames="fadeIn"
      in={props.isOpen}
      timeout={300}
      unmountOnExit
    >
      <DrawerOverlayBackground
        customStyle={props.customStyle}
        data-testid="NavbarDrawerOverlay"
        onClick={props.onClose}
      />
    </CSSTransition>
  );
};
