import styled from 'styled-components';
import tw from 'tailwind.macro';
import FunnelSteps from '../Funnel/Steps';

const Container = styled.div`
  ${tw`flex items-center justify-center`}
  grid-column-start: 4;
  grid-column-end: 10;
`;

export const FunnelNavigation = () => {
  return (
    <Container data-testid="NavbarFunnelNavigation">
      <FunnelSteps />
    </Container>
  );
};
