import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import tw from 'tailwind.macro';

export const NavbarWrapperStyle = css`
  max-width: 1440px;
  ${tw`w-full mx-auto bg-white px-medium2 py-medium`}
`;

export const FlagStyle: CSSProperties = {
  width: '25.5px',
  height: '17px',
  borderRadius: '4px',
};

export const BackButton = styled.button`
  ${tw`p-0 flex justify-start items-center lg:hidden`}
  grid-column-start: 1;
  grid-column-end: 2;
`;

export const MenuLink = styled(Link)<{ isHidden?: boolean }>`
  ${tw`text-p-medium leading-p-medium font-semibold text-text no-underline hover:text-primary-400 p-0`}
  ${({ isHidden }) => isHidden && tw`hidden`}
`;
