import React from 'react';
import styled from 'styled-components';
import { IconProps } from './types';

const SVG = styled.svg<IconProps>`
  ${({ customStyle }) => customStyle}
`;

export const EuroCoinIcon: React.FC<IconProps> = ({ color, customStyle }) => (
  <SVG
    customStyle={customStyle}
    fill="none"
    height="23"
    viewBox="0 0 22 23"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3333 7.77316C13.4487 6.98142 12.2806 6.5 11 6.5C8.23858 6.5 6 8.73858 6 11.5C6 14.2614 8.23858 16.5 11 16.5C12.2806 16.5 13.4487 16.0186 14.3333 15.2268M5 13H10M5 10H10M21 11.5C21 17.0228 16.5228 21.5 11 21.5C5.47715 21.5 1 17.0228 1 11.5C1 5.97715 5.47715 1.5 11 1.5C16.5228 1.5 21 5.97715 21 11.5Z"
      stroke={color ?? 'white'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </SVG>
);
