import React from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

interface IconProps {
  color?: string;
  customStyle?: FlattenSimpleInterpolation;
}

const SVG = styled.svg<IconProps>`
  ${({ customStyle }) => customStyle}
`;

export const WalletIcon: React.FC<IconProps> = ({ color, customStyle }) => (
  <SVG
    customStyle={customStyle}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 14H16.51M3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V9C21 7.89543 20.1046 7 19 7L5 7C3.89543 7 3 6.10457 3 5ZM3 5C3 3.89543 3.89543 3 5 3H17M17 14C17 14.2761 16.7761 14.5 16.5 14.5C16.2239 14.5 16 14.2761 16 14C16 13.7239 16.2239 13.5 16.5 13.5C16.7761 13.5 17 13.7239 17 14Z"
      stroke={color ?? 'white'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </SVG>
);
