import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import { NAVIGATION } from '../../containers/App/constants';
import { isMobileApp } from '../../helpers/app';
import { Navigation } from './View/Navigation';

const Container = styled.nav`
  ${tw`sticky z-2 bg-white pin-b pin-r pin-l px-medium2 py-medium`};
  padding-bottom: max(var(--size-medium), env(safe-area-inset-bottom));

  box-shadow: 0px 0px 20px rgba(157, 163, 206, 0.5);
`;

export const APP_BOTTOM_NAVIGATION_HIDDEN_PATHS = [
  NAVIGATION.RESULTS,
  NAVIGATION.INFO,
];

type AppBottomNavigationProps = {
  isKeyboardOpen: boolean;
};

export const AppBottomNavigation = (props: AppBottomNavigationProps) => {
  const location = useLocation();
  const pathname = location.pathname;

  const isApp = isMobileApp(navigator.userAgent);
  const canBeDisplayed = !APP_BOTTOM_NAVIGATION_HIDDEN_PATHS.some((url) =>
    pathname.startsWith(url),
  );

  if (!isApp || !canBeDisplayed || props.isKeyboardOpen) {
    return null;
  }

  return (
    <Container data-testid="appBottomNavigation">
      <Navigation pathname={pathname} />
    </Container>
  );
};
