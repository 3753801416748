import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { NAVIGATION } from '../../containers/App/constants';
import IconUrlTictactrip from '../../images/logoTextBlack.svg';
import IconUrlC from '../../images/logoTextBlackC.svg';
import { screens } from '../../styles/constants';

const LogoContainer = styled.div<{ isPartner: boolean }>`
  ${tw`col-start-2 flex justify-center lg:justify-start`}
  grid-column-start: 2;
  grid-column-end: 4;

  @media (min-width: ${screens.lg}) {
    ${({ isPartner }) =>
      isPartner
        ? `
        grid-column-start: 1;
        grid-column-end: 4;
        `
        : `
        grid-column-start: 1;
        grid-column-end: 3;
    `}
  }
`;

const Logo = styled.img`
  ${tw`flex w-full`};

  object-fit: contain;
  height: 36px;
  max-width: 130px;

  @media (min-width: 768px) {
    height: 51px;
  }
`;

const LogoSeparator = styled.p`
  ${tw`text-center w-abs1`}
`;

const LogoLink = styled(Link)`
  ${tw`text-text flex items-center justify-center`};
  gap: var(--size-small);
  text-decoration: none;
`;

// Adapt logo depending on the environment
const LogoUrl =
  window.location.href.indexOf('comparatrip.eu') > -1
    ? IconUrlC
    : IconUrlTictactrip;

type LogosProps = {
  partnerLogoUrl?: string;
};

export const NavbarLogos = (props: LogosProps) => {
  return (
    <LogoContainer data-testid="NavbarLogos" isPartner={!!props.partnerLogoUrl}>
      <LogoLink to={NAVIGATION.HOME}>
        {props.partnerLogoUrl && (
          <Fragment>
            <span>
              <Logo
                alt="logo"
                sizes="(max-width: 768px) 36px, 51px"
                src={props.partnerLogoUrl}
                width={130}
              />
            </span>

            <LogoSeparator>
              <strong>X</strong>
            </LogoSeparator>
          </Fragment>
        )}

        <span>
          <Logo
            alt="logo"
            sizes="(max-width: 768px) 36px, 51px"
            src={LogoUrl}
            width={130}
          />
        </span>
      </LogoLink>
    </LogoContainer>
  );
};
