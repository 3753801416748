import { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled, { css } from 'styled-components';
import tw from 'tailwind.macro';
import { NAVIGATION } from '../../containers/App/constants';
import { changeLocale } from '../../containers/LanguageProvider/actions';
import { makeSelectLocale } from '../../containers/LanguageProvider/selectors';
import { useApp } from '../../contexts/App';
import { isMobileApp } from '../../helpers/app';
import { useScrollLock } from '../../hooks/useScrollLock';
import { ChevronIcon } from '../../images/icons/chevron';
import { colors, screens } from '../../styles/constants';
import history from '../../utils/history';
import { Icon18 } from '../Icon';
import { NavbarWrapperStyle } from './Common';
import { BackButton as BackButtonContainer } from './Common';
import {
  Language,
  SECONDARY_DRAWER_SECTIONS,
  SecondaryDrawerSections,
} from './config';
import { DesktopNavigation } from './DesktopNavigation';
import { DrawerOverlay } from './DrawerOverlay';
import { FunnelNavigation } from './FunnelNavigation';
import { NavbarLogos } from './NavbarLogos';
import { PrimaryDrawer } from './PrimaryDrawer';
import { SecondaryDrawer, SecondaryDrawerProps } from './SecondaryDrawer';

const Container = styled.div<{ isInFunnel: boolean }>`
  ${tw`w-full bg-white relative shadow-header sticky lg:relative`}
  top: 0;
  z-index: 5;
  height: max-content;

  ${({ isInFunnel }) =>
    isInFunnel &&
    css`
      @media (max-width: ${screens.lg}) {
        ${tw`hidden`}
      }
    `}

  padding-top: env(safe-area-inset-top);
`;

const Wrapper = styled.div`
  ${NavbarWrapperStyle}

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: var(--size-medium);

  @media (min-width: ${screens.lg}) {
    grid-template-columns: repeat(12, 1fr);
    column-gap: var(--size-medium2);
  }
`;

export type NavbarProps = {
  language: Language;
  setLanguage: (language: Language) => void;
};

export const Navbar = (props: NavbarProps) => {
  const { isInFunnel, partner } = useApp();

  const [secondaryDrawerContext, setSecondaryDrawerContext] = useState<
    Pick<SecondaryDrawerProps, 'isOpen' | 'data'>
  >({
    data: null,
    isOpen: false,
  });
  const [isPrimaryDrawerOpen, setIsPrimaryDrawerOpen] =
    useState<boolean>(false);

  const { lock, unlock } = useScrollLock({
    autoLock: false,
  });

  useEffect(() => {
    if (isPrimaryDrawerOpen || secondaryDrawerContext.isOpen) {
      lock();
    } else {
      unlock();
    }

    return unlock;
  }, [isPrimaryDrawerOpen, lock, secondaryDrawerContext.isOpen, unlock]);

  const closeSecondaryDrawer = () => {
    setSecondaryDrawerContext((context) => ({
      ...context,
      isOpen: false,
    }));
  };

  const onOpenPrimaryDrawer = () => {
    closeSecondaryDrawer();
    setIsPrimaryDrawerOpen(true);
  };

  const onOpenSecondaryDrawer = (section: SecondaryDrawerSections) => {
    setIsPrimaryDrawerOpen(false);
    setSecondaryDrawerContext({
      isOpen: true,
      data: SECONDARY_DRAWER_SECTIONS[section],
    });
  };

  const onSecondaryButtonClick = (sectionId: string, itemId: string) => {
    if (sectionId === 'languagesSection') {
      props.setLanguage(itemId as any);
    }
  };

  const isApp = isMobileApp(navigator.userAgent);

  return (
    <Fragment>
      <Container isInFunnel={isInFunnel}>
        <Wrapper>
          <BackButton />

          <NavbarLogos partnerLogoUrl={partner?.logoUrl} />

          {isInFunnel ? (
            <FunnelNavigation />
          ) : (
            <DesktopNavigation
              isMobileApp={isApp}
              language={props.language}
              onOpenPrimaryDrawer={onOpenPrimaryDrawer}
              onOpenSecondaryDrawer={onOpenSecondaryDrawer}
              partner={partner}
            />
          )}
        </Wrapper>

        <SecondaryDrawer
          data={secondaryDrawerContext.data}
          isOpen={secondaryDrawerContext.isOpen}
          onClose={closeSecondaryDrawer}
          onSecondaryButtonClick={onSecondaryButtonClick}
        />
      </Container>

      <PrimaryDrawer
        isMobileApp={isApp}
        isOpen={isPrimaryDrawerOpen}
        onClose={() => setIsPrimaryDrawerOpen(false)}
        onOpenSecondaryDrawer={onOpenSecondaryDrawer}
      />

      <DrawerOverlay
        customStyle={css`
          z-index: 4;
          overflow-y: scroll;
        `}
        isOpen={secondaryDrawerContext.isOpen}
        onClose={closeSecondaryDrawer}
      />
    </Fragment>
  );
};

const BackButton = () => {
  if (history.length <= 2 || window.location.pathname === NAVIGATION.HOME) {
    return null;
  }

  return (
    <BackButtonContainer
      data-testid="NavbarBackButton"
      onClick={() => {
        history.goBack();
      }}
    >
      <ChevronIcon
        color={colors['secondary-400']}
        customStyle={css`
          ${Icon18}
        `}
      />
    </BackButtonContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  language: makeSelectLocale(),
});

export function mapDispatchToProps(dispatch) {
  return {
    setLanguage: (locale) => dispatch(changeLocale(locale)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Navbar);
