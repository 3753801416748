import { lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import LazyLoading from '../../components/LazyLoading';

export const LazySearch = lazy(() =>
  import(/* webpackChunkName: 'Search' */ './index'),
);

export default function Search(props) {
  const { destination, origin, pageType } = useParams();
  return (
    <Suspense fallback={<LazyLoading />}>
      <LazySearch {...props} key={`${origin}-${destination}-${pageType}`} />
    </Suspense>
  );
}
