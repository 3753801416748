/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

import PropTypes from 'prop-types';
import { colors } from '../../styles/constants';

export const SET_SEARCH_URL = 'app/SET_SEARCH_URL';
export const SET_FUNNEL_STEP = 'app/SET_FUNNEL_STEP';
export const SET_PRIMARY_COLOR = 'app/SET_PRIMARY_COLOR';
export const SET_SECONDARY_COLOR = 'app/SET_SECONDARY_COLOR';
export const SET_COLORS = 'app/SET_COLORS';
export const SET_PARTNER_CONFIG = 'app/SET_PARTNER_CONFIG';
export const GET_PARTNER_CONFIG = 'app/GET_PARTNER_CONFIG';

export const COLORS = 'colors';
export const PRIMARY_COLOR = 'primaryColor';
export const SECONDARY_COLOR = 'secondaryColor';
export const PARTNER_CONFIG = 'partnerConfig';

export const STEP_NONE = 'app/STEP_NONE';
export const STEP_RESULTS = 'app/STEP_RESULTS';
export const STEP_PAYMENT = 'app/STEP_PAYMENT';
export const STEP_PASSENGERS_INFO = 'app/STEP_PASSENGERS_INFO';
export const SET_PARTNER_ID = 'app/SET_PARTNER_ID';
export const SET_PARTNER_EXTERNAL_ID = 'app/SET_PARTNER_EXTERNAL_ID';
export const FUNNEL_STEPS = [
  { id: STEP_RESULTS, name: 'stepResults' },
  { id: STEP_PASSENGERS_INFO, name: 'stepInfos' },
  { id: STEP_PAYMENT, name: 'stepPayment' },
];

export const FUNNEL_STEP = 'funnelStep';
export const SEARCH_URL = 'searchUrl';
export const PARTNER_ID = 'partnerId';
export const PARTNER_EXTERNAL_ID = 'partnerExternalId';

export const OUTBOUND = 'outboundTrip';
export const RETURN = 'returnTrip';
export const PASSENGERS = 'passengersInfo';

export const DEFAULT_TITLE = 'Réservez vos voyages responsables en 3 clics';
export const TITLE_SUFFIX = ' | Tictactrip.eu';
export const DEFAULT_DESCRIPTION =
  'Comparez, combinez et réservez tous vos voyages en train, bus et covoiturage sur une plateforme unique.';
export const META_DEFAULT_IMAGE = `${document.location.origin}/assets/defaultOgImage.png`;

export const NAVIGATION = {
  HOME: '/',
  RESULTS: '/results',
  REDIRECT: '/redirect',
  REDIRECT_APP: '/redirect/app',
  INFO: '/info',
  PAYMENT: '/payment',
  CONFIRMATION: '/confirmation',
  PARTNERS: '/partners',
  WIDGET: '/widget',
  LEGAL: '/legal',
  TERMS_OF_SALE: '/terms',
  ABOUT: '/about',
  HOW_IT_WORKS: '/about/how-it-works',
  FAQ: '/faq',
  PRIVACY: '/privacy',
  CITY: '/city',
  COMPANY: '/company',
  SEARCH: '/search',
  MOUNTAIN: '/mountain',
  STATIONS: '/stations',
  LOGIN: '/login',
  VERIFY_MAGIC_LINK: '/login/verify',
  PAYMENT_LINK: '/payment-link',
  ORDERS: '/orders',
  ADMIN: '/admin',
  WIDGET_BOOKING: '/widget-booking',
  FUNDRAISING: 'https://www.tudigo.co/equity/tictactrip',
  PROVIDERS_CONFIGURATION: '/providers-configuration',
  SUPPORT: '/support',
  STATUS: '/status',
  BUY_A_DISCOUNT_CARD: '/buy/discount-card',
  GIVE_YOUR_FEEDBACK: '/avis-client',
  IOS_APP_LINK:
    'https://apps.apple.com/fr/app/tictactrip-trains-bus-covoit/id1500431872',
  ANDROID_APP_LINK:
    'https://play.google.com/store/apps/details?id=com.tictactrip.comparateur.bus.train.sncf',
  FEATURE_FLAGS: '/feature-flags',
  BLOG: 'https://www.tictactrip.eu/blog/',
  JOBS: 'https://www.welcometothejungle.com/fr/companies/tictactrip',
  TTA: 'https://tally.so/r/mBaPRA',
  AFFILIATION_BOOKING_COM: 'https://api.tictactrip.eu/redirects/8573',
  AFFILIATION_GET_YOUR_GUIDE: 'https://api.tictactrip.eu/redirects/8574',
  AFFILIATION_RENTAL_CARS: 'https://api.tictactrip.eu/redirects/8575',
  AFFILIATION_NANNYBAG: 'https://api.tictactrip.eu/redirects/8576',
  PRESS:
    'https://tictactrip.notion.site/Tictactrip-Espace-presse-partag-b95294e3262e49b3a3491cd49b673fb1?pvs=74',
  HELP_CENTER: 'https://tictactriphelp.zendesk.com/hc/fr',
};

export const themeMock = {
  colors: {
    primary: colors.terciary500,
    secondary: colors.primary400,
  },
};

export const themePropTypes = PropTypes.shape({
  colors: PropTypes.shape({
    primary: PropTypes.string,
    secondary: PropTypes.string,
  }),
});

export const locationPropTypes = PropTypes.shape({
  hash: PropTypes.string,
  key: PropTypes.string,
  pathname: PropTypes.string,
  search: PropTypes.string,
  state: PropTypes.string,
});

export interface LocationProps {
  hash?: string;
  key?: string;
  pathname?: string;
  search?: string;
  state?: string;
}

export const partnerConfigPropTypes = PropTypes.shape({
  backgroundUrl: PropTypes.string,
  gpuids: PropTypes.arrayOf(PropTypes.string),
  header: PropTypes.string,
  id: PropTypes.string,
  logoUrl: PropTypes.string,
  placeholder: PropTypes.string,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  subHeader: PropTypes.string,
});

export const appValuesPropTypes = PropTypes.shape({
  [COLORS]: PropTypes.shape({
    [PRIMARY_COLOR]: PropTypes.string,
    [SECONDARY_COLOR]: PropTypes.string,
  }),
  [FUNNEL_STEP]: PropTypes.string,
  [PARTNER_CONFIG]: partnerConfigPropTypes,
  [PARTNER_ID]: PropTypes.string,
  [SEARCH_URL]: PropTypes.string,
});

export const ALT_REVIEW_PROFILE_PIC = 'user profile';
